import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import useTrans from '@/hooks/useTrans'
import CustomAccordion from '@/components/base/CustomAccordion'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'

function QuestionAnswer() {
  const trans = useTrans()
  const [isSeeMore, setIsSeeMore] = useState(false)
  const [expanded, setExpanded] = useState<string | false>('')
  const [currentTab, setCurrentTab] = useState('general')
  const [items, setItems] = useState([])
  const isMobile = useMobileDetection()

  const handleChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    },
    [],
  )

  const toggleReadMore = useCallback(() => {
    setIsSeeMore(!isSeeMore)
  }, [isSeeMore])

  useEffect(() => {
    const xItems = isSeeMore
      ? trans.landing.question_answer.details[currentTab]
      : trans.landing.question_answer.details[currentTab].slice(0, 3)
    setItems(xItems)
  }, [currentTab, isSeeMore, trans])

  const handleSelectedTabs = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <Box className="bg-white text-iw-dark-gray">
      <Container className="relative py-10 sm:py-20 flex flex-col items-center gap-8">
        <Typography className="font-bold font-ma text-[1.75rem] sm:text-4xl text-center text-iw-charcoal-black">
          {trans.landing.question_answer.title}
        </Typography>

        <Box className="w-full border border-solid border-iw-primary-light bg-iw-white-snow rounded-2xl p-4">
          <Tabs
            value={currentTab}
            onChange={handleSelectedTabs}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className="items-center justify-center"
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: isMobile ? 'start' : 'center',
              },
            }}
          >
            {Object.keys(trans.landing.question_answer.tabs).map((key, index) => (
              <Tab
                value={key}
                key={uuidv4()}
                className={`uppercase not-italic ${
                  key === currentTab
                    ? 'text-iw-secondary font-semibold'
                    : 'text-iw-dark-gray font-normal'
                }`}
                label={trans.landing.question_answer.tabs[key]}
              />
            ))}
          </Tabs>
        </Box>

        <Box className="w-full border border-solid border-iw-primary-light bg-iw-white-snow rounded-2xl p-4 sm:p-14">
          {items.map((item: { question: string; answer: string }, index: number) => (
            <CustomAccordion
              key={index}
              item={item}
              index={index}
              expanded={expanded}
              handleChange={handleChange}
            />
          ))}
        </Box>

        {trans.landing.question_answer.details[currentTab].length > 3 && (
          <Button
            className="normal-case m-auto px-6 py-2 bg-iw-primary-light text-iw-primary font-medium"
            onClick={toggleReadMore}
            endIcon={
              isSeeMore ? (
                <ExpandLess fontSize="large" color="primary" />
              ) : (
                <ExpandMore fontSize="large" color="primary" />
              )
            }
          >
            {isSeeMore ? trans.common.expand_less : trans.common.expand_more}
          </Button>
        )}
      </Container>
    </Box>
  )
}

export default QuestionAnswer
