import { memo } from 'react'
import { Box } from '@mui/material'

import Hero from './components/hero'
import Stats from './components/stats'
import WhyToChoose from './components/why-to-choose'
import HowItWorks from './components/how-it-works'
import AsEasyAsItGet from './components/as-easy-as-it-get'
import GapCta from './components/gap-cta'
import AboutUs from './components/about-us'
import QuestionAnswer from './components/question-answer'
import SendMoneyNow from '@/components/base/SendMoneyNow'

const View = () => {

  return (
    <Box className="bg-white">
      <Hero />
      <Stats />
      <WhyToChoose />
      <HowItWorks />
      <AsEasyAsItGet />
      <GapCta />
      <AboutUs />
      <QuestionAnswer />
      <SendMoneyNow />
    </Box>
  )
}

const HomeWithMemo = memo(View)
HomeWithMemo.displayName = 'HomeView'
export default HomeWithMemo
