import { Box, Button, Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import Image, { StaticImageData } from 'next/image'

import React from 'react'
import useTrans from '@/hooks/useTrans'
import HighSpeedsImage from '@/assets/landing-high-speeds.png'
import ZeroFeeImage from '@/assets/landing-0-fee.png'
import NoHassel from '@/assets/landing-no-hassel.png'

const CardItem = ({
  icon,
  title,
  description,
}: {
  icon: StaticImageData
  title: string
  description: string
}) => {
  return (
    <Stack
      className="h-full px-6 py-10 rounded-xl flex flex-col bg-white border-[1.5px] border-solid border-iw-primary-light"
      gap={3}
    >
      <Image alt="why-to-choose-icon" width={70} src={icon} />
      <Stack gap={1}>
        <Typography className="font-bold text-iw-neutral-black text-xl sm:text-2xl">
          {title}
        </Typography>
        <Typography className="text-sm text-iw-dark-gray mt-1">{description}</Typography>
      </Stack>
    </Stack>
  )
}

function WhyToChoose() {
  const trans = useTrans()
  const theme = useTheme()
  const images = [HighSpeedsImage, ZeroFeeImage, NoHassel]
  return (
    <Box className="bg-white" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-20 flex flex-col gap-4 items-center">
        <Typography className="font-bold font-ma text-center text-iw-charcoal-black text-[1.75rem] sm:text-4xl">
          {trans.landing.why_to_choice.title}
        </Typography>
        <Typography className="text-iw-dark-gray text-center md:w-2/3">{trans.landing.why_to_choice.description}</Typography>
        <Grid container spacing={2} className="mt-0">
          {trans.landing.why_to_choice.items.map(
            (item: { title: string; description: string }, index: number) => (
              <Grid item key={index} md={4} sm={3} xs={12}>
                <CardItem icon={images[index]} title={item.title} description={item.description} />
              </Grid>
            ),
          )}
        </Grid>
        <Button
          className="px-14 mt-8"
          variant="contained"
          color="primary"
          component="a"
          href="/transfer-init"
        >
          {trans.landing.why_to_choice.send_money_now}
        </Button>
      </Container>
    </Box>
  )
}

export default WhyToChoose
