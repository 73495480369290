import { Container, Grid, Stack, Typography } from '@mui/material'
import useTrans from '@/root/src/hooks/useTrans'

const Item = ({ title, description }: { title: string; description: string }) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Stack className="text-[#F2F1F2]" alignItems="center" gap={2}>
        <Typography className="font-ma font-extrabold text-5xl">{title}</Typography>
        <Typography className="text-xl">{description}</Typography>
      </Stack>
    </Grid>
  )
}

function Stats() {
  const trans = useTrans()

  return (
    <Container className="my-16">
      <Stack alignItems="center" className="bg-iw-gradient-primary rounded-2xl py-14 px-4 sm:py-20 sm:px-32" gap={6}>
        <Typography className='font-bold font-ma text-center text-white text-4xl'>{trans.landing.stats.title}</Typography>
        <Grid container spacing={4}>
          {trans.landing.stats.items.map(
            (item: { title: string; description: string }, index: number) => (
              <Item key={index} {...item} />
            ),
          )}
        </Grid>
      </Stack>
    </Container>
  )
}

export default Stats
