import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

import Image from 'next/image'

import React from 'react'
import useTrans from '@/hooks/useTrans'
import GapCtaImage from '@/assets/introduce.png'

function GapCta() {
  const trans = useTrans()
  const theme = useTheme()

  return (
    <Box className="bg-iw-light-sky-blue" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-20 flex flex-col gap-10 items-center">
        <Typography className="font-bold font-ma text-center text-iw-charcoal-black text-[1.75rem] sm:text-4xl sm:w-3/4 md:w-1/2">
          {trans.landing.why_to_choice.title_gap_cta}
        </Typography>

        <Stack direction="row" gap={2}>
          <Image alt="fiat-vnd-image" src={staticImportIcon(IconName.FIAT_VND)} height={48} />
          <Image alt="fiat-usd-image" src={staticImportIcon(IconName.FIAT_USD)} height={48} />
        </Stack>

        <Button
          className="px-14"
          variant="contained"
          color="primary"
          component="a"
          href="/transfer-init"
        >
          {trans.landing.why_to_choice.send_money_now}
        </Button>

        <Image
          alt="gap-cta-image"
          src={GapCtaImage}
          className="rounded-2xl h-[360px] static"
          fill
          style={{ objectFit: 'cover', objectPosition: 'center center' }}
        />
      </Container>
    </Box>
  )
}

export default GapCta
