import React, { useCallback, useMemo, useState } from 'react'
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'

import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import CardCarousel from '@/components/base/CardCarousel'

import AsEasyAs1Vi from '@/assets/as-easy-as-1-vi.png'
import AsEasyAs2Vi from '@/assets/as-easy-as-2-vi.png'
import AsEasyAs3Vi from '@/assets/as-easy-as-3-vi.png'
import AsEasyAs4Vi from '@/assets/as-easy-as-4-vi.png'

import AsEasyAs1En from '@/assets/as-easy-as-1-en.png'
import AsEasyAs2En from '@/assets/as-easy-as-2-en.png'
import AsEasyAs3En from '@/assets/as-easy-as-3-en.png'
import AsEasyAs4En from '@/assets/as-easy-as-4-en.png'

const CardItem = ({
  index,
  title,
  description,
  items,
  note,
}: {
  index: number
  title: string
  description: string
  items: string[]
  note: string
}) => {
  return (
    <Stack alignItems="start" justifyContent="space-between" className="h-full p-5 mt-4 sm:mt-0 sm:p-0 sm:pb-10 sm:pr-10">
      <Stack gap={0.5}>
        <Box className="relative">
          <Typography className="font-bold font-ma text-[1.75rem] sm:text-[2rem] text-iw-secondary">
            {String(index + 1).padStart(2, '0')}
          </Typography>
          <Image
            alt={IconName.ABSTRACT_LINE}
            className="absolute -top-3 left-8 w-[27px] h-[29px] sm:-top-5 sm:left-10 sm:w-[39px] sm:h-[43px] z-10"
            width={39}
            height={43}
            src={staticImportIcon(IconName.ABSTRACT_LINE)}
          />
        </Box>
        <Typography className="text-iw-charcoal-black text-lg font-semibold">{title}</Typography>
        <Typography className="text-iw-dark-gray">{description}</Typography>
      </Stack>
      <Stack className="flex-1 w-full mt-4 py-6 sm:py-4 border-t border-solid border-iw-primary-light">
        {items.map((item, index) => (
          <Stack
            direction="row"
            alignItems="self-start"
            key={index}
            className="text-iw-primary mb-2"
            gap={1}
          >
            <Typography className="font-semibold">{index + 1}.</Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: item }}
              className="flex-1 font-semibold"
            />
          </Stack>
        ))}
      </Stack>
      <Typography dangerouslySetInnerHTML={{ __html: note }} />
    </Stack>
  )
}

function AsEasyAsItGet() {
  const [currTab, setCurrentTab] = useState(0)
  const trans = useTrans()
  const theme = useTheme()
  const isMobile = useMobileDetection()
  const { locale } = useRouter()

  const handleTabClick = useCallback((tab: number) => {
    setCurrentTab(tab)
  }, [])

  const imageDetails = useMemo(() => {
    const isEng = locale === 'en'
    return [
      isEng ? AsEasyAs1En : AsEasyAs1Vi,
      isEng ? AsEasyAs2En : AsEasyAs2Vi,
      isEng ? AsEasyAs3En : AsEasyAs3Vi,
      isEng ? AsEasyAs4En : AsEasyAs4Vi,
    ]
  }, [locale])

  const tabDetail = trans.landing.as_easy_as_it_get.details[currTab]

  return (
    <Box className="bg-white" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-20 flex flex-col gap-4 items-center">
        <Typography className="font-bold font-ma text-center text-iw-charcoal-black text-[1.75rem] sm:text-4xl">
          {trans.landing.as_easy_as_it_get.title}
        </Typography>
        <Typography className="text-iw-dark-gray text-center">
          {trans.landing.as_easy_as_it_get.description}
        </Typography>

        <Box className="w-full border border-solid border-iw-primary-light bg-iw-white-snow rounded-2xl my-5">
          {!isMobile ? (
            <Grid container spacing={4} className="mt-0">
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className="md:mt-0 md:pl-20 md:pr-0 sm:pl-16 sm:pr-10 sm:pt-10"
              >
                <Image src={imageDetails[currTab]} alt="as-easy-as-image" />
              </Grid>
              <Grid item md={6} sm={12} xs={12} className="pt-14 md:pl-10 sm:pl-20">
                <CardItem index={currTab} {...tabDetail} />
              </Grid>
            </Grid>
          ) : (
            <CardCarousel
              id="as-easy-as-it-get"
              xs={1}
              sm={1}
              md={2}
              items={trans.landing.as_easy_as_it_get.details.map((item: any, index: number) => (
                <Stack key={uuidv4()}>
                  <Image src={imageDetails[index]} alt="as-easy-as-image" />
                  <CardItem index={index} {...item} />
                </Stack>
              ))}
            />
          )}
        </Box>
        <Grid container spacing={3} className="hidden sm:flex">
          {trans.landing.as_easy_as_it_get.tabs.map(
            (item: { title: string; description: string }, index: number) => (
              <Grid key={uuidv4()} item xs={12} md={3} sm={3}>
                <Stack
                  className={`py-3 cursor-pointer border-t-4 border-solid ${
                    currTab === index ? 'border-iw-secondary' : 'border-[#EAEAEA]'
                  }`}
                  gap={1}
                  onClick={() => handleTabClick(index)}
                >
                  <Typography className="text-iw-charcoal-black text-lg font-bold">
                    {String(index + 1).padStart(2, '0')}
                  </Typography>
                  <Typography
                    className={`font-semibold ${
                      currTab === index ? 'text-iw-secondary' : 'text-iw-charcoal-black'
                    }`}
                  >
                    {item.title}
                  </Typography>
                  <Typography className="text-iw-dark-gray text-sm">{item.description}</Typography>
                </Stack>
              </Grid>
            ),
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default AsEasyAsItGet
