import React from 'react'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import TransferInput from '@/components/transaction_input'
import HeroWorldMap from '@/assets/hero-world-map.png'
import { useCurrentTransaction } from '@/root/src/providers/transaction'

const Hero = () => {
  const trans = useTrans()
  const { storeTransaction } = useCurrentTransaction()

  return (
    <Container className="relative">
      <Image
        className="absolute mt-10 sm:mt-32 xs:inset-0 right-0 m-auto opacity-20"
        height={391}
        src={HeroWorldMap}
        alt="hero-wait-list"
      />
      <Grid container className="relative text-iw-dark-gray">
        <Grid item xs={12} sm={12} md={6} className="flex flex-col items-center sm:items-start py-5 sm:py-24 gap-4">
          <Stack className="relative" alignItems={{ xs: 'center', sm: 'start' }}>
            <Typography
              className="font-bold font-ma text-[2rem] text-iw-neutral-black text-center sm:text-left sm:font-extrabold sm:text-5xl sm:leading-tight"
              dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.title }}
            />
            <Stack className="relative w-fit">
              <Typography
                className="font-bold font-ma text-[2rem] text-iw-neutral-black text-center sm:text-left sm:font-extrabold sm:text-5xl sm:leading-tight"
                dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.with_no_fee }}
              />
              <Image
                alt={IconName.ABSTRACT_LINE}
                className="absolute -right-8 top-0 w-7 sm:w-10 sm:top-0 sm:-right-10"
                src={staticImportIcon(IconName.ABSTRACT_LINE)}
              />
            </Stack>
          </Stack>

          <Typography
            className="text-center sm:text-left"
            dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.description }}
          />

          <Stack
            direction="row"
            alignItems="center"
            className="pl-4 p-1 mt-4 border-2 border-dashed border-iw-primary-light rounded-3xl w-fit"
            gap={2}
          >
            <Typography className="text-sm text-iw-primary font-medium">
              {trans.landing.hero_waitlist.supported_country}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              className="p-2 bg-iw-primary-light rounded-3xl"
            >
              <Image
                alt={IconName.FIAT_VND}
                src={staticImportIcon(IconName.FIAT_VND)}
                width={24}
                height={24}
              />
              <Image
                alt={IconName.FIAT_USD}
                src={staticImportIcon(IconName.FIAT_USD)}
                width={24}
                height={24}
              />
              <Image
                alt={IconName.FIAT_NGN}
                src={staticImportIcon(IconName.FIAT_NGN)}
                width={24}
                height={24}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TransferInput isLanding handleSubmit={storeTransaction} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Hero
