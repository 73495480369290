import React, { memo } from 'react'
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import Image, { StaticImageData } from 'next/image'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'

import HowToWork1MainImage from '@/assets/how-to-work-1.png'
import HowToWork2MainImage from '@/assets/how-to-work-2.png'
import HowToWork3MainImage from '@/assets/how-to-work-3.png'
import HowToWork4MainImage from '@/assets/how-to-work-4.png'

import HowToWorkDashDesktopImage from '@/assets/svgs/how-it-works-dash-desktop.svg'
import HowToWorkDashMobileImage from '@/assets/svgs/how-it-works-dash-mobile.svg'

const CardItem = ({
  index,
  icon,
  title,
  description,
  isMobile,
}: {
  index: number
  icon: {
    main: StaticImageData
    hMain: number
  }
  title: string
  description: string
  isMobile: boolean
}) => {
  const isRotate = index % 2 === 0
  return (
    <>
      {index > 0 && (
        <Stack alignItems="center" className="my-4">
          <Image
            className={`${!isRotate ? '' : 'transform scale-x-[-1]'} m-auto z-0 sm:w-3/4 md:w-1/2`}
            alt="how-to-work-image"
            height={110}
            src={!isMobile ? HowToWorkDashDesktopImage : HowToWorkDashMobileImage}
          />
        </Stack>
      )}
      <Grid container spacing={2} className="mt-0 sm:h-[330px]">
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          className="relative"
          order={{ xs: 1, sm: isRotate ? 1 : 2 }}
        >
          <Image
            className="static sm:absolute inset-0 m-auto z-20"
            alt="how-to-work-image"
            height={icon.hMain}
            src={icon.main}
          />
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          className={`flex items-center ${isRotate ? 'sm:pr-28' : 'sm:pl-28'}`}
          order={{ xs: 1, sm: isRotate ? 2 : 1 }}
        >
          <Stack gap={0.5}>
            <Typography className="font-bold text-iw-primary text-[1.75rem] sm:text-[2rem]">
              {title}
            </Typography>
            <Typography className="text-sm text-iw-dark-gray mt-1">{description}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

function HowItWorks() {
  const trans = useTrans()
  const theme = useTheme()
  const isMobile = useMobileDetection()

  const images = [
    {
      main: HowToWork1MainImage,
      hMain: 250,
    },
    {
      main: HowToWork2MainImage,
      hMain: 293,
    },
    {
      main: HowToWork3MainImage,
      hMain: 320,
    },
    {
      main: HowToWork4MainImage,
      hMain: 292,
    },
  ]
  return (
    <Box className="bg-iw-white-snow" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-20 flex flex-col gap-4 items-center">
        <Stack gap={2}>
          <Typography className="text-left w-full font-bold font-ma text-iw-charcoal-black text-[1.75rem] sm:text-4xl">
            {trans.landing.how_it_works.title}
          </Typography>
          <Typography className="text-left w-full text-iw-dark-gray sm:w-2/3 md:w-1/2">
            {trans.landing.how_it_works.description}
          </Typography>
        </Stack>
        <Stack>
          {trans.landing.how_it_works.items.map(
            (item: { title: string; description: string }, index: number) => (
              <CardItem
                index={index}
                key={uuidv4()}
                icon={images[index]}
                title={item.title}
                description={item.description}
                isMobile={isMobile}
              />
            ),
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default memo(HowItWorks)
