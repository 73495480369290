import React from 'react'
import { Box, Container, Stack, Typography, useTheme } from '@mui/material'

import useTrans from '@/hooks/useTrans'
import Dots from '@/assets/svgs/dots.svg'
import Image from 'next/image'
function AboutUs() {
  const trans = useTrans()
  const theme = useTheme()

  return (
    <Box className="bg-white" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-20 flex flex-col gap-4 items-center">
        <Stack
          className="w-full border border-solid border-iw-primary-light bg-iw-white-snow rounded-2xl py-6 px-3 sm:px-14 sm:py-16"
          gap={2}
        >
          <Stack gap={2}>
            <Image src={Dots} alt="dots" height={16} />
            <iframe
              data-testid='play-video-about-use'
              src={trans.landing.about_us.url}
              allow="autoplay; encrypted-media"
              className="rounded-lg w-full h-[279px] sm:h-[560px]"
              title="video"
            />
          </Stack>
          <Typography className="font-semibold text-xl sm:text-2xl text-iw-charcoal-black">
            {trans.landing.about_us.title}
          </Typography>
          <Typography className="text-iw-dark-gray w-full sm:w-3/4 md:w-2/3">
            {trans.landing.about_us.description}
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default AboutUs
